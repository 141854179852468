/* Shim for deprecated uvoAutocomplete. Only modifies html layout for compatibility */
$.fn.uvoAutocomplete = $.fn.uvoAutocomplete || function() {
  return this.each(function(_, el) {
    if (el.uvoAutocompleteShimInitialized) return;
    el.uvoAutocompleteShimInitialized = true;
    var $el = $(el);
    var $wrapper = $('<div class="uvo_pref-uvoAutocomplete-wrapper" />');
    var $icon = $('<span class="ui-icon ui-icon-triangle-1-s" style="position: absolute; cursor: pointer; right: 10px; top: 0px;" />');
    $el.wrap($wrapper);
    $el.after($icon);
    $el.addClass('uvo_pref-uvoAutocomplete-input');
    $el.css({ appearance: 'none', '::-ms-expand': 'none' });
    $icon.css({ pointerEvents: 'none' });
  })
};


(function (window, document) {
	function init() {
		var element = document.getElementById('CommonPage');

		if (element) {
			var pageName = element.getAttribute('data-page');
			var siteId = element.getAttribute('data-site-id');
			var policies = element.getAttribute('data-policies');
			var policyId = element.getAttribute('data-policy-id');

			let widgetOptions = {
				siteId,
				pageName,
			};

			if (policies && policyId) {
				widgetOptions = { siteId, pageName, policies, policyId };
			}

			window.commonPagesWidget.widget(element, widgetOptions);
		}
	}

	if (typeof window.commonPagesWidget !== 'undefined') {
		init();
	} else {
		var script = document.createElement('script');
		script.src = '/assets/js/commonPagesWidget.min.js';
		script.async = true;
		script.onload = function () {
			init();
		};
		document.getElementsByTagName('head')[0].appendChild(script);
	}
})(window, document);



//ToggleList
function toggle_list() {
  $('.toggle-list .toggle-content').addClass('hide');
  $(document).on('click', '.toggle-list .toggle-link', function () {
    if ($(this).next().hasClass('hide')) {
      $(this).next().slideDown(500);
    } else {
      $(this).next().slideUp(500);
    }
    $(this).parent().toggleClass('active');
    $(this).next().toggleClass('hide');
    return false;
  });
}


var toggleList = document.querySelector('.toggle-list');
var faqPage = document.querySelector('[data-page="frequently_asked_questions"]');
var moneyBackPage = document.querySelector('[data-page="money_back_guarantee"]');

if (toggleList || faqPage || moneyBackPage) {
  setTimeout(function () {
    toggle_list()
  }, 3000);
}


/**
 * Description: JS for essaypro.net
 * Version: 1.0.0
 * Last update: 2015/08/01
 * Author: UVOCorp
 *
 * Summary:
 *
 *  0. matchMedia() polyfill
 *    - Test a CSS media type/query in JS. Authors & copyright (c) 2012: Scott Jehl, Paul Irish, Nicholas Zakas. Dual MIT/BSD license
 *  1. Custom JS for essaypro.net
 *  2. Calculator for essaypro.net
 *
 */

/* ==========================================================================
   0. matchMedia() polyfill
   ========================================================================== */

window.matchMedia=window.matchMedia||(function(e,f){var c,a=e.documentElement,b=a.firstElementChild||a.firstChild,d=e.createElement("body"),g=e.createElement("div");g.id="mq-test-1";g.style.cssText="position:absolute;top:-100em";d.style.background="none";d.appendChild(g);return function(h){g.innerHTML='&shy;<style media="'+h+'"> #mq-test-1 { width: 42px; }</style>';a.insertBefore(d,b);c=g.offsetWidth==42;a.removeChild(d);return{matches:c,media:h}}})(document);


/* ==========================================================================
   1. Custom JS for essaypro.net
   ========================================================================== */


$(function() {
	//add class "selected" for item select
	if ($.fn.uvoAutocomplete) {
		$('.place-your-order select').uvoAutocomplete({
			onOpen: function(e) {
				var lebelEl = this.label;
				$(this._listElement.children()).each(function() {
					if ($(this).text() == lebelEl) {
						$(this).addClass('selected')
					}
				});
			}
		});
	}
	//count pages
	$.fn.counts = function(options) {
			//defaultOptions
			options = $.extend({
				minusClass: 'pageskol-left',
				minimum: 1
			}, options);
			var inputCol = $(this);
			if ($(inputCol).val() <= options.minimum) {
				$('span[class=' + options.minusClass + ']').css({
					'opacity': '0.5',
					'cursor': 'default'
				});
			} else {
				$('span[class=' + options.minusClass + ']').css({
					'opacity': '1',
					'cursor': 'pointer'
				});
			}
			$(inputCol).parent().find('span').click(function() {
				if ($(this).hasClass(options.minusClass)) {
					if ($(inputCol).val() >= options.minimum) {
						var count = parseInt($(inputCol).val()) - 1;
						count = count < options.minimum ? options.minimum : count;
					} else {
						$(inputCol).val(options.minimum - 1);
						var count = parseInt($(inputCol).val()) - 1;
						count = count < options.minimum ? options.minimum : count;
					}
					if ($(inputCol).val() <= (options.minimum + 1)) {
						$('span[class=' + options.minusClass + ']').css({
							'opacity': '0.5',
							'cursor': 'default'
						});
					}
				} else {
					if ($(inputCol).val() >= options.minimum) {
						var count = parseInt($(inputCol).val()) + 1;
					} else {
						$(inputCol).val(options.minimum - 1);
						var count = parseInt($(inputCol).val()) + 1;
					}
					if ($(inputCol).val() > (options.minimum - 1)) {
						$('span[class=' + options.minusClass + ']').css({
							'opacity': '1',
							'cursor': 'pointer'
						})
					}
				}
				$(inputCol).val(count);
				$(inputCol).change();
				$(this).parent().next().find('span').html(275 * count);
				return false;
			});
			$(this).keydown(function(e) {
				if (e.which != 8 && e.which != 0 && (e.which < 37 || e.which > 57) && e
					.which != 96 && e.which != 97 && e.which != 98 && e.which != 99 && e.which !=
					100 && e.which != 101 && e.which != 102 && e.which != 103 && e.which !=
					104 && e.which != 105) {
					return false;
				}
				var key, keyChar;
				if (!e) var e = window.e;
				if (e.keyCode) key = e.keyCode;
				else if (e.which) key = e.which;
				if (key == 38 || key == 39 || (e.which < 48 || e.which > 57) && e.which !=
					96 && e.which != 97 && e.which != 98 && e.which != 99 && e.which !=
					100 && e.which != 101 && e.which != 102 && e.which != 103 && e.which !=
					104 && e.which != 105) {
					if (key == 40 || key == 37) {
						if ($(this).val() >= options.minimum) {
							var count = parseInt($(this).val()) - 1;
							count = count < options.minimum ? options.minimum : count;
						} else {
							$(this).val(options.minimum - 1);
							keyChar = String.fromCharCode(key);
							var count = parseInt($(this).val()) + 1;
						}
					} else {
						if ($(this).val() >= options.minimum) {
							var count = parseInt($(this).val()) + 1;
						} else {
							$(this).val(options.minimum - 1);
							var count = parseInt($(this).val()) + 1;
						}
					}
					$(this).val(count);
					$(this).parent().next().find('span').html(275 * count);
					if ($(inputCol).val() <= (options.minimum)) {
						$('span[class=' + options.minusClass + ']').css({
							'opacity': '0.5',
							'cursor': 'default'
						});
					} else {
						$('span[class=' + options.minusClass + ']').css({
							'opacity': '1',
							'cursor': 'pointer'
						})
					}
				}
			});
		}
		//counts
	$('#input-kol').counts();

	//toplink click
	$('.to-top').click(function() {
		$('body,html').animate({
			scrollTop: 0
		}, 800);
		return false;
	});

	//toggle topmenu
	$('.toggle-menu').click(function() {
		$(this).toggleClass('active');
		if ($(this).next().css('display') == 'none') {
			$(this).next().show();
		} else {
			$(this).next().hide();
		}
		return false;
	});

	if ($('.topmenu-line').length > 0) {
		var tmenu = $('.topmenu-line'),
			tmenuTop = $('.topmenu-line').offset().top;
	}

	$(window).scroll(function() {
		//fixed menu
		var scrlTopPos = $(window).scrollTop(),
			windowWidth = parseInt($(window).width()) + 17;
		if (windowWidth >= 968) {
			if (scrlTopPos <= tmenuTop) {
				$(tmenu).removeClass('fixed');
			} else if (scrlTopPos > tmenuTop) {
				$(tmenu).addClass('fixed');
			}
		}

		//show to-top link
		if ($(window).scrollTop() >= 200) {
			$('.to-top').fadeIn();
		} else {
			$('.to-top').fadeOut();
		}
	});

	//preorder
	var form = $('.place-your-order');

	if (form.length) {
		var deadlines = null;
		$.get('/uvoform/get_deadlines_js/', function(response) {
			if (response.deadlines && response.servicetype2dids) {
				init(response);
			}
		}, 'JSON');
		var rebuildDeadlines = function() {
			var deadline_field = form.find('[name="deadline"]:first'),
				course_lvl_id = form.find('[name="academic_level"]:first').val(),
				service_type_id = 0;
			deadline_field.empty();
			course_lvl_id = parseInt(course_lvl_id);
			if (deadlines && deadlines.deadlines) {
				for (var prop in deadlines.deadlines) {
					var deadline = deadlines.deadlines[prop];
					if (deadline.course_lvl_id === course_lvl_id && deadline.servicetype_id ==
						service_type_id) {
						var option = $('<option />').attr('value', deadline.deadline_id).html(
							deadline.deadline_name);
						deadline_field.append(option);
					}
				}
			}
			deadline_field.trigger('rebuild.uvoAutocomplete');
		};
		var init = function(response) {
			deadlines = response;
			var pages = $('#input-kol');
			var academiclvl = form.find('[name="academic_level"]');
			var deadlines_field = form.find('[name="paper_type_id"]');
			deadlines_field.uvoAutocomplete();
			var papertype = form.find(
				'[name="paper_type_id"], [name="academic_level"]').uvoAutocomplete({
				onSelect: rebuildDeadlines
			});
			rebuildDeadlines(response);
			form.submit(function() {
				$.post('/uvo/calculator/submit/', form.serialize(), function(response) {
					if (response && response.success) {
						window.location.href = response['data']['url'];
					}
				}, 'JSON');
				return false;
			});
		};
	}

	//password reminder
	//for inquiry page
	$('.show-hide').find('.hidden').hide();
	$('#inq-done-more').click(function() {
		if (!$(this).hasClass('less')) {
			$(this).parents('.show-hide').find('.hidden').slideDown(500);
			$(this).text('Read less').addClass('less');
		} else {
			$(this).parents('.show-hide').find('.hidden').slideUp(500);
			$(this).text('Read more').removeClass('less');
		}
	});
	$('.plagcheckc aside#rightplagpanel').clone().appendTo('.plagcheckc').addClass(
		'rgt-spec-plag').attr('id', 'rightplagpanel_2');
});

/* ==========================================================================
   2. Calculator for writing-expert.com
   ========================================================================== */

/* =============================
*  NEW CALC INIT
================================ */
//CALC MINI
$.getScript('/assets/js/calc.js', function () {
	if ($('[data-calc-mini]').length > 0) {
		new Calc({
			ID: '18',
			calculatorType: 'DefaultCalculator',
			format: 'html',
			environment: getEnvironment
		});
	}
});
/* =============================
*  end NEW CALC INIT
================================ */

(function(window, document) {
  function init() {
    window.NotifyWidget({
      siteId: '18',
      container: '.NotifyWidget-banner'
    });
  }

  if (typeof window.NotifyWidget !== 'undefined') {
    init();
  } else {
    const script = document.createElement('script');
    script.src = '/assets/js/notifyWidget.min.js';
    script.async = true;
    script.onload = function () {
      init();
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }
})(window, document);
